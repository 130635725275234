.underline-one {
    color: #ffffff; /* Цвет обычной ссылки */
    position: relative;
    cursor: pointer;
    text-decoration: none; /* Убираем подчеркивание */
    display: inline-block;
    margin-left: 30px;
    line-height: 2;
    font-size: 1.1em;
    font-family: 'Roboto', sans-serif;
}
.drop {
    color: #4f4f4f;
}
.drop:hover {
    color: #4f4f4f;
}
#collasible-nav-dropdown {
    color: #ffffff;
}

.underline-one:hover {
    color: #e33935;
}
.underline-one:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    bottom: -3px;
    width: 0;
    height: 2px; /* Высота линии */
    background-color: #ffffff; /* Цвет подчеркивания при исчезании линии*/
    transition: width 0.5s; /* Время эффекта */
}

.underline-one:hover:after {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    bottom: -3px;
    height: 2px; /* Высота линии */
    background-color: #e33935; /* Цвет подчеркивания при появлении линии*/
    transition: width 0.5s;  /* Время эффекта */
}
@media (max-width: 992px) {
    .underline-one {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        font-size: 1.1em;
    }
    .underline-one:hover:after,
    .underline-one:focus:after {
        width: 0;
    }
}

.dropdown-menu.show {
    --bs-dropdown-link-active-bg: 0;
}
.dropdown-toggle.show {
    padding: 5%;
}