@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Raleway:wght@600&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');



h1 {
    font-family: 'Raleway', sans-serif;
}
p {
    font-family: 'PT Sans', sans-serif;
}
ul {
    font-family: 'PT Sans', sans-serif;
}
a {
    font-family: 'Roboto', sans-serif;
}
body {
    background-color: #eaf9fd;
}
::-webkit-scrollbar {
    width: 12px; /* ширина полосы прокрутки */
}

::-webkit-scrollbar-track {
    background-color: #212529; /* цвет фона полосы прокрутки */
}

::-webkit-scrollbar-thumb {
    background-color: #e33935; /* цвет полосы прокрутки */
    height: 200px;
}

.spin {
    background: #e33935;
    width: 50px;
    height: 50px;
}

.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    border-radius: 100px;
    transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.scroll-to-top-button.show {
    opacity: 1;
    visibility: visible;
}
.sticky-top {
    position: sticky;
    top: -1px;
}