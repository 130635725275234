.razd1 {
    background-image: url('../assets/car.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    transition: 1.1s;
    cursor: pointer;
    overflow: hidden; /* добавлено */
}
.legkovaya {
    padding: 0;
    transition: 1s;
}
.razd1:hover {
    background-position: bottom;
    padding: 6%;
}
.razd2:hover {
    background-position: bottom;
    padding: 6%;
}
.razdtext {
    transition: 0.7s;
}
@media (max-width: 767px) {
    .razd1:hover .razdtext {
        font-size: 2em;
        transform: scale(0.95);
        overflow: hidden;
    }
}
a.razdtext {
    text-decoration: none;
}
.razd1:hover .razdtext {
    font-size: 3.5em;
    transform: scale(0.95);
    overflow: hidden;
}

.razd2 {
    background-image: url('../assets/cran.png');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: 100%;
    transition: 1.1s;
    cursor: pointer;
    overflow: hidden; /* добавлено */
}
.razd2:hover .razdtext {
    font-size: 3.5em;
    transform: scale(0.95);
    overflow: hidden;
}
@media (max-width: 992px) {
    .razd1:hover .razdtext {
        font-size: 3em;
        transform: scale(0.8);
        overflow: hidden;
    }
    .razd2:hover .razdtext {
        font-size: 3em;
        transform: scale(0.8);
        overflow: hidden;
    }
}

.razdtext:hover {
    transform: scale(1.2); /* добавлено */
    font-size: 3em;
}

.info-card {
    height: 100%;
    width: 90%;
    margin-left: 5%;
    background-color: #f2f2f2;
    border: none;
    border-radius: 10px;
    opacity: 0.8;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.info-card:hover {
    opacity: 1;
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.75);
}

.info-card .card-body {
    text-align: center;
}

.info-card .card-title {
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.info-card .card-text {
    font-size: 1rem;
    color: #666;

}

.info-card .fa-icon {
    color: #007bff;
}
.preim {
    padding: 3%;
   background: url("/src/assets/супер1.png") no-repeat fixed;
   background-size: cover;
}
.preim1 {
    padding: 3%;
    background: url("/src/assets/trucks.png") no-repeat fixed;
    background-size: cover;
    background-position: center;
}
.preim2 {
    padding: 4%;
    background: url("/src/assets/crane2.png") no-repeat fixed;
    background-size: cover;
    background-position: bottom;
}
.preim3 {
    padding: 4%;
    background: url("/src/assets/backcar.png") no-repeat fixed;
    background-size: cover;
    background-position: top;
}
.preimicon {
    color: #e33935;
}
.preimicon1 {
    color: #5a93f6;
}
.prem {
    color: #ffffff;
}

.rental-conditions {
    background: -webkit-linear-gradient(171deg, #121212,#181818);
    background: linear-gradient(171deg, #121212,#181818);
    background-size: cover;
    padding: 3%;
}
.rental-conditions1 {
    background: -webkit-linear-gradient(171deg, #121212,#181818);
    background: linear-gradient(171deg, #121212,#181818);
    padding: 1%;
}
.rental-conditions h4 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #e13935;

}

.rental-conditions ul {
    list-style: none;
    margin: 0;
    padding: 0;

}

.rental-conditions ul li {
    margin-bottom: 0.3rem;
    font-size: 1rem;
    color: #e5e4e4;

}
.info-card1 {
    background: #212529;
}
.info-card1:hover {
    box-shadow: 0 8px 16px 0 rgba(248, 48, 48, 0.75);

}

@media (max-width: 768px) {
    .info-card1 {
        width: 100%;
        margin-left: 0;
    }
}


@media (min-width: 768px) {
    .rental-conditions .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.cat {
    margin-top: -2%;
}

